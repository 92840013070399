<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    disabledAllFields
    @cancel="onCancel('SupportRequests')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'SupportRequestEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'SupportRequests',
      pageHeader: 'Редактирование заявки',
      breadcrumbs: [
        {
          text: 'Заявки',
          route: { name: 'RequestsMain' },
        },
        {
          text: 'Заявки',
          route: { name: 'SupportRequests' },
        },
        {
          text: 'Редактирование заявки',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      customers: (state) => state.common.customers,
      initiationStatuses: (state) => state.common.initiationStatuses,
      gisTypes: (state) => state.common.gisTypes,
    }),

    messagesList() {
      if (this.form?.messages === undefined) return [];
      return this.form.messages.map((item) => {
        const pocessedItem = {};
        for (const key in item) {
          if (typeof item[key] === 'boolean') {
            pocessedItem[key] = item[key] ? 'Да' : 'Heт';
          } else {
            pocessedItem[key] = item[key];
          }
        }
        return pocessedItem;
      }).sort((a, b) => {
        const dateA = this.$moment(a.added, "DD.MM.YYYY HH:mm:ss");
        const dateB = this.$moment(b.added, "DD.MM.YYYY HH:mm:ss");
        
        return dateB - dateA;
    });
    },

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'number',
              label: 'Номер',
            },
            {
              type: 'text',
              key: 'name',
              label: 'Название',
            },
            {
              type: 'text',
              key: 'customer',
              label: 'Имя клиента',
            },
            { type: 'text', key: 'author', label: 'Имя автора' },
            {
              type: 'dateTime',
              key: 'added',
              label: 'Дата создания',
            },
            {
              type: 'text',
              key: 'lockedBy',
              label: 'Заблокирован',
            },
            {
              type: 'text',
              key: 'settingsIntegrationCustomType',
              label: 'ИС начислений',
            },
            {
              type: 'text',
              key: 'initiationStatus',
              label: 'Общий статус',
            },
            {
              type: 'text',
              key: 'status',
              label: 'Статус',
            },
            {
              type: 'text',
              key: 'gisType',
              label: 'Тип',
            },
            {
              type: 'decimalNumber',
              key: 'planHours',
              label: 'План. часы',
              attributes: {
                options: {
                  minimumValue: 0,
                  decimalPlaces: 1,
                },
              },
            },
            { type: 'checkbox', alias: 'isLowMark', label: 'Низкая оценка' },
          ],
        },
        {
          tab: 'Сообщения',
          table: {
            caption: 'Сообщения',
            headers: [
              {
                text: 'Дата создания',
                alias: 'added',
              },
              {
                text: 'Текст сообщения',
                alias: 'text',
              },
              {
                text: 'Скрытый',
                alias: 'isHidden',
              },
              {
                text: 'Автор',
                alias: 'author',
              },
              {
                text: 'Сообщение',
                alias: 'isClientMessage',
              },
            ],
            items: this.messagesList,
            key: 'messages',
          },
        },
      ];
    },
  },

  created() {
    const customers = this.getCommonList({ name: 'Customers' });
    const initiationStatuses = this.getCommonList({
      name: 'InitiationStatuses',
    });
    const gisTypes = this.getCommonList({ name: 'GisTypes' });

    this.isLoadingPage = true;
    Promise.all([customers, initiationStatuses, gisTypes]).then(() => {
      this.isLoadingPage = false;
      this.getItem({ name: this.controllerName, id: this.id })
        .then(() => {
          this.form = this.lodash.cloneDeep(this.item.data);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
